import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Moment from "react-moment"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Footer from "../components/footer"

import wave from "../images/wave.svg"
import cta_box from "../images/cta_box.svg"

export const query = graphql`
  query CaseStudyQuery($id: String!) {
    strapiCaseStudy(strapiId: { eq: $id }) {
      strapiId
      slug
      heading
      caseStudyStats {
        statValue
        statDescription
      }
      products {
        productName
        productDescription
        productImage {
          url
        }
      }
      headerImage {
        url
      }
      logo {
        url
      }
      objectiveDescription
      solutionDescription
      solutionImage {
        url
      }
      testimonialText
      testimonialPersonName
      testimonialPersonTitle
      testimonialProfileImage {
        url
      }
    }
  }
`

const CaseStudy = ({ data }) => {
  const caseStudy = data.strapiCaseStudy

  return (
    <Layout>
      <SEO
        title={caseStudy.heading}
        description={caseStudy.objectiveDescription}
        image={caseStudy.headerImage.url}
      />

      <section className="w-full relative bg-gray-800 bg-cover bg-center" style={{backgroundImage: `url(${caseStudy.headerImage.url})`}}>
        <header className="w-full text-center px-5 py-20 md:py-24 bg-gradient-to-t from-black">
          <div className="inline-block w-auto mx-auto h-20 p-2 bg-white rounded-lg">
            <img src={caseStudy.logo.url} alt="" 
            className="block h-16 mx-auto" />
          </div>
          <h1 className="text-3xl md:text-5xl text-white shadow-sm font-bold leading-none tracking-tight md:mt-12">
            {caseStudy.heading}
          </h1>
        </header>
      </section>

      <section className="max-w-3xl mx-auto text-center mt-24">
        <h1 className="text-2xl md:text-4xl text-center font-bold text-gray-700 leading-none mt-4 md:mt-3">
          Objective
        </h1>
        <p className="text-md md:text-xl font-light text-gray-400 mt-3">
          {caseStudy.objectiveDescription}
        </p>
      </section>

      <section className="w-full md:px-10 relative bg-gray-100 md:py-24 mt-24">
        <div class="max-w-3xl mx-auto flex flex-col md:flex-row justify-between">

          {caseStudy.caseStudyStats.map((stat, i) => {
            return (
              <div class="w-64 mx-auto text-center mt-5">
              <h3 class="text-5xl font-black text-orange-500 ">{stat.statValue}</h3>
              <p class="font-light text-sm text-gray-400 mr-4">
              {stat.statDescription}
              </p>
          </div>
            )
          })}

        </div>
      </section>

      <section
        id="solution"
        className="mt-12 md:mt-0 py-10 md:py-32 px-5 mx-5 md:mx-10 rounded-xl"
      >
        <div className="max-w-4xl mx-auto flex flex-col-reverse md:flex-row">
          <div className="w-full md:w-1/2 text-center md:text-left md:pr-20">
            <h2 className="text-2xl md:text-4xl text-center md:text-left font-bold text-gray-700 leading-none mt-4 md:mt-3">
              Solution
            </h2>
            <p className="text-md md:text-xl text-center md:text-left font-light text-gray-400 max-w-md mt-4 mb-10">
              {caseStudy.solutionDescription}
            </p>

          </div>
          <div className="flex w-full md:w-1/2">
            <img
              className="bg-gray-100 self-center mx-auto w-full mb-10 md:mb-0 rounded-xl"
              src={caseStudy.solutionImage.url}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="w-full px-5 md:mb-24">

        <h2 className="text-xl md:text-2xl text-center font-bold text-gray-700 leading-none mt-4 md:mt-3">
          Products used
        </h2>

        <div class="max-w-3xl mx-auto flex flex-col md:flex-row justify-center mt-10">

          {caseStudy.products.map((product, i) => {
            return (
              <div class="w-full md:w-64 text-center mt-5">
                <img src={product.productImage.url} alt="" className="w-10 h-10 mx-auto"/>
                <h3 class="text-lg font-bold text-gray-700 mt-4">{product.productName}</h3>
                <p class="font-light text-sm text-gray-400 mr-4">
                {product.productDescription}
                </p>
              </div>
            )
          })}

        </div>
      </section>

      <img className="w-full mt-10" src={wave} alt="" />

      <section className="container mx-auto px-5 md:px-0 text-center">
        <div className="w-full rounded-xl p-8 md:p-24">
          <p className="font-medium tracking-tight text-gray-700 text-xl md:text-3xl">
            "{caseStudy.testimonialText}”
          </p>
          <div className="inline-flex mt-4 leading-none">
            <img className="w-10 h-10 mr-2" src={caseStudy.testimonialProfileImage.url} alt="" />
            <div className="mt-1 text-left">
              <p className="">{caseStudy.testimonialPersonName}</p>
              <p className="text-sm text-gray-400 mt-1">{caseStudy.testimonialPersonTitle}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="w-full px-4 mt-10 md:mt-24">
        <div className="container mx-auto flex justify-between bg-bluelight-100 overflow-hidden rounded-lg flex-col md:flex-row text-center md:text-left">
          <div className="p-10">
            <h2 className="text-xl md:text-3xl text-gray-700 font-bold leading-snug max-w-xl">
              Want to see how Flow can help you find{" "}
              quality <span className="wrap-sm">buyers</span> and <span className="wrap-sm">tenants</span>, introduce you to
              landlords or sell your properties?
            </h2>

            <Link
              to="/book-demo"
              className="inline-block btn-primary text-center mt-10"
            >
              Book a demo
            </Link>
          </div>
          <div className="hidden md:flex">
            <img
              className="self-end mr-12 bottom-0"
              style={{ height: "220px" }}
              src={cta_box}
              alt=""
            />
          </div>
        </div>
      </section>

      

      <Footer />
    </Layout>
  )
}

export default CaseStudy
